@use '@angular/material' as mat;

$ey-font-book: 'Etihad-ALtis-Book';
$ey-font-medium-regular: 'Etihad-Altis-Medium-Regular';
$ey-font-text-regular: 'Etihad-Altis-Text-Regular';
$ey-font-text-medium: 'EtihadAltis-Medium';
$ey-font-text-bold: 'EtihadAltis-Bold';

@font-face {
  font-family: $ey-font-book;
  font-display: swap;
  src:
    url('./assets/fonts/Etihad-Altis/Etihad-Altis-Book.woff2') format('woff2'),
    url('./assets/fonts/Etihad-Altis/Etihad-Altis-Book.ttf') format('truetype'),
    url('./assets/fonts/Etihad-Altis/Etihad-Altis-Book.eot')
      format('embedded-opentype');
}

@font-face {
  font-family: $ey-font-medium-regular;
  font-display: swap;
  src:
    url('./assets/fonts/Etihad-Altis/Etihad-Altis-Medium-Regular.woff2')
      format('woff2'),
    url('./assets/fonts/Etihad-Altis/Etihad-Altis-Medium-Regular.ttf')
      format('truetype'),
    url('./assets/fonts/Etihad-Altis/Etihad-Altis-Medium-Regular.eot')
      format('embedded-opentype');
}

@font-face {
  font-family: $ey-font-text-regular;
  font-display: swap;
  src:
    url('./assets/fonts/Etihad-Altis/Etihad-Altis-Text-Regular.woff2')
      format('woff2'),
    url('./assets/fonts/Etihad-Altis/Etihad-Altis-Text-Regular.ttf')
      format('truetype'),
    url('./assets/fonts/Etihad-Altis/Etihad-Altis-Text-Regular.eot')
      format('embedded-opentype');
}

@font-face {
  font-family: $ey-font-text-medium;
  font-display: swap;
  src:
    url('./assets/fonts/Etihad-Altis/EtihadAltis-Medium.woff') format('woff2'),
    url('./assets/fonts/Etihad-Altis/EtihadAltis-Medium.ttf') format('truetype'),
    url('./assets/fonts/Etihad-Altis/EtihadAltis-Medium.eot')
      format('embedded-opentype');
}

@font-face {
  font-family: $ey-font-text-bold;
  font-display: swap;
  src:
    url('./assets/fonts/Etihad-Altis/EtihadAltis-Bold.woff2') format('woff2'),
    url('./assets/fonts/Etihad-Altis/EtihadAltis-Bold.ttf') format('truetype'),
    url('./assets/fonts/Etihad-Altis/EtihadAltis-Bold.eot')
      format('embedded-opentype');
}

* {
  font-family: $ey-font-text-regular;
}

$light-theme-palette: (
  50: #e3f2fd,
  100: #badffb,
  200: #8fcbfa,
  300: #61b7f7,
  400: #3ca7f6,
  500: #0c98f5,
  600: #078ae7,
  700: #bd8b29,
  800: #0067c2,
  900: #0049a3,
  A100: #c2e4ff,
  A200: #addcff,
  A400: #1f9eff,
  A700: #00518f,
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: rgba(black, 0.87),
    400: rgba(black, 0.87),
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: rgba(black, 0.87),
    A200: rgba(black, 0.87),
    A400: white,
    A700: white,
  ),
);
$gainsboro-palette: (
  50: #f9f9fa,
  100: #f3f3f4,
  200: #ebebec,
  300: #dcdcdd,
  400: #b9b9ba,
  500: #99999a,
  600: #707071,
  700: #5d5d5e,
  800: #3e3e3f,
  900: #1d1d1e,
  A100: #f5f5f5,
  A200: #eaeaeb,
  A400: #d6d6d7,
  A700: #838386,
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: rgba(black, 0.87),
    400: rgba(black, 0.87),
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: rgba(black, 0.87),
    A200: rgba(black, 0.87),
    A400: rgba(black, 0.87),
    A700: white,
  ),
);
$crimson-palette: (
  50: #ffebef,
  100: #ffcdc5,
  200: #f0999f,
  300: #e77179,
  400: #f24e58,
  500: #f83a40,
  600: #e9313e,
  700: #d72638,
  800: #ca1e30,
  900: #bb0d24,
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: rgba(black, 0.87),
    400: white,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
  ),
);
$light-theme-primary: mat.define-palette($light-theme-palette, 700, 300, 900);
$light-theme-accent: mat.define-palette($gainsboro-palette, 300, 100, 500);
$light-theme-warn: mat.define-palette($crimson-palette, 700);
$light-theme: mat.define-light-theme(
  (
    color: (
      primary: $light-theme-primary,
      accent: $light-theme-accent,
      warn: $light-theme-warn,
    ),
  )
);
$dark-theme: mat.define-dark-theme(
  (
    color: (
      primary: $light-theme-primary,
      accent: $light-theme-accent,
      warn: $light-theme-warn,
    ),
  )
);
@include mat.all-component-themes($light-theme);

.dark-theme {
  @include mat.all-component-themes($dark-theme);
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
}

.page-title-container {
  padding: 20px;
  background: white;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  font-size: 1.5rem;
}
